import Script from 'next/script';
import { useEffect } from 'react';

import { useCurrentUser } from '@/contexts/currentUser';
import { CONFIG_GTM_ID } from '@/utils/constants/config';
import { IS_ENV_PRODUCTION } from '@/utils/constants/env';

const isLoadable = IS_ENV_PRODUCTION && CONFIG_GTM_ID;

const GoogleTagManagerScript: React.FC = () => {
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const win = window as any;

    if (!isLoadable || currentUser === null) {
      return;
    }

    // eslint-disable-next-line dot-notation
    win['dataLayer'] = win['dataLayer'] || [];
    // eslint-disable-next-line dot-notation
    win['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js', currentUser });
  }, [currentUser]);

  if (!isLoadable) {
    return null;
  }

  return (
    <Script id="gtm" src={`https://www.googletagmanager.com/gtm.js?id=${CONFIG_GTM_ID}`} async={true} defer={true} />
  );
};

export default GoogleTagManagerScript;
