import '../index.css';
import 'prismjs';

import { ApolloProvider } from '@apollo/client';
import { Box, ChakraProvider } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';

import * as favicons from '@/assets/favicons';
import safariPinnedTab from '@/assets/safari-pinned-tab.svg?url';
import colors from '@/chakra/colors';
import theme from '@/chakra/theme';
import { AccountsProvider } from '@/contexts/accounts';
import { OrgsProvider } from '@/contexts/organizations';
import { localClient } from '@/graphql/client';
import { CONFIG_SENTRY_ENVIRONMENT, CONFIG_VERSION } from '@/utils/constants/config';
import SENTRY_ENVIRONMENTS from '@/utils/constants/sentry';
import usePageTransitionEffect from '@/utils/hooks/usePageTransitionEffect';

import { CurrentUserProvider } from '../contexts/currentUser';
import GoogleTagManagerScript from './__components__/GoogleTagManagerScript';
import IntercomScript from './__components__/IntercomScript';

const {
  favicon,
  favicon16,
  favicon32,
  favicon96,
  favicon120,
  favicon128,
  favicon152,
  favicon180,
  favicon192,
  favicon196,
  favicon270,
} = (() => {
  switch (CONFIG_SENTRY_ENVIRONMENT) {
    case SENTRY_ENVIRONMENTS.Production:
      return favicons.production;
    case SENTRY_ENVIRONMENTS.Development:
    default:
      return favicons.development;
  }
})();

const sentryColor = (() => {
  if (CONFIG_SENTRY_ENVIRONMENT === SENTRY_ENVIRONMENTS.Production) {
    return colors.purple[0];
  }

  return colors.grey[0];
})();

const App: React.FC<AppProps & { nonce: string; Component: NextPageWithLayout }> = ({
  Component,
  Component: { Layout = ({ children }) => <Box>{children}</Box> },
  pageProps,
}) => {
  usePageTransitionEffect();

  return (
    <ApolloProvider client={localClient}>
      <ChakraProvider theme={theme}>
        <Head>
          {/* Generics */}
          <link rel="icon" href={favicon} />
          <link rel="icon" type="image/png" href={favicon16} sizes="16x16" />
          <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
          <link rel="icon" type="image/png" href={favicon96} sizes="96x96" />
          <link rel="icon" type="image/png" href={favicon128} sizes="128x128" />
          <link rel="icon" type="image/png" href={favicon192} sizes="192x192" />
          <link rel="mask-icon" href={safariPinnedTab} color={sentryColor} />

          {/* Android */}
          <link rel="shortcut icon" type="image/png" href={favicon196} sizes="196x196" />

          {/* iOS */}
          <link rel="apple-touch-icon" type="image/png" href={favicon120} sizes="120x120" />
          <link rel="apple-touch-icon" type="image/png" href={favicon152} sizes="152x152" />
          <link rel="apple-touch-icon" type="image/png" href={favicon180} sizes="180x180" />

          {/* Windows 8 IE 10, Windows 8.1 + IE11 and above */}
          <meta name="app-version" content={CONFIG_VERSION} />
          <meta name="msapplication-TileColor" content={sentryColor} />
          <meta name="msapplication-square70x70logo" content={favicon128} />
          <meta name="msapplication-square150x150logo" content={favicon270} />
          <meta name="msapplication-TileImage" content={favicon270} />
          <meta name="msapplication-config" content="none" />
        </Head>
        <Script id="prismWindow">{`
            window.Prism = window.Prism || {};
            window.Prism.manual = true;
          `}</Script>
        <CurrentUserProvider>
          <AccountsProvider>
            <OrgsProvider>
              <IntercomScript />
              <GoogleTagManagerScript />
              <Layout {...pageProps}>
                <Component {...pageProps} />
              </Layout>
            </OrgsProvider>
          </AccountsProvider>
        </CurrentUserProvider>
      </ChakraProvider>
    </ApolloProvider>
  );
};

export default App;
